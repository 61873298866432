import {useQuery} from '@tanstack/react-query';
import getProfile from '../services/getProfile';

const useProfile = (enabled = true) => {
  return useQuery({
    queryKey: ['user/profile'],
    queryFn: getProfile,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled,
  });
};

export default useProfile;
