import {BaseSizes, Borders} from './types';

export const baseBorders: BaseSizes = {
  none: 0,
  xs5: 1,
  xs4: 2,
  xs3: 4,
  xs2: 6,
  xs: 8,
  s: 12,
  m: 16,
  l: 20,
  full: 9999,
};

const borders: Borders = Object.assign(baseBorders, {
  desktop: {
    border_width: {regular: baseBorders.xs5, thick: baseBorders.xs4},
    border_radius: {
      none: baseBorders.none,
      xsmall: baseBorders.xs2,
      small: baseBorders.xs,
      main: baseBorders.m,
      large: baseBorders.l,
      pill: baseBorders.full,
    },
  },
  tablet: {
    border_width: {regular: baseBorders.xs5, thick: baseBorders.xs4},
    border_radius: {
      none: baseBorders.none,
      xsmall: baseBorders.xs2,
      small: baseBorders.xs,
      main: baseBorders.m,
      large: baseBorders.l,
      pill: baseBorders.full,
    },
  },
  mobile: {
    border_width: {regular: baseBorders.xs5, thick: baseBorders.xs4},
    border_radius: {
      none: baseBorders.none,
      xsmall: baseBorders.xs4,
      small: baseBorders.xs3,
      main: baseBorders.xs,
      large: baseBorders.m,
      pill: baseBorders.full,
    },
  },
});

export default borders;
