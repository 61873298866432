import Button from '@/components/ui/Button';
import Link from '@/components/ui/Link';
import {List, X} from '@phosphor-icons/react/dist/ssr';
import * as Portal from '@radix-ui/react-portal';
import {useState} from 'react';
import NextLink from 'next/link';
import styles from './styles.module.css';

import { SignUpButton, SignInButton } from '@clerk/nextjs';
import { Sign } from 'crypto';

const navItems = [
  {label: 'Browse Listings', href: '/'},
  {label: 'Features', href: 'https://www.offerwell.com/#feature'},
  {label: 'About Us', href: 'https://www.offerwell.com/#about-us'},
  {label: 'Contact Us', href: '/contact-us'},
];

const Nav = () => {
  const [isOpenNav, setIsOpenNav] = useState(false);

  const handleNavToggle = () => {
    setIsOpenNav(!isOpenNav);
  };

  return (
    <>
      <div className={styles.mobileBtn}>
        <Button
          variant={isOpenNav ? undefined : 'primary_mono_light'}
          isIconOnly
          onClick={handleNavToggle}>
          {isOpenNav ? <X></X> : <List></List>}
        </Button>
        {isOpenNav ? (
          <Portal.Root>
            <div className={styles.mobileNav}>
              <nav>
                <ul className={styles.mobileNavItems}>
                  {navItems.map(({label, href}) => (
                    <li key={label}>
                      <Link asChild onClick={handleNavToggle}>
                        <NextLink href={href}>{label}</NextLink>
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
              <div className={styles.navAuthBtns}>
                <SignUpButton mode="modal">
                  <Button size="small">
                    Register Free
                  </Button>
                </SignUpButton>
                <SignInButton mode="modal">
                  <Button variant="secondary_mono_dark" size="small">
                    Sign In
                  </Button>
                </SignInButton>
              </div>
            </div>
          </Portal.Root>
        ) : null}
      </div>
      <nav className={styles.nav}>
        <ul className={styles.navItems}>
          {navItems.map(({label, href}) => (
            <li key={label}>
              <Link asChild>
                <NextLink href={href}>{label}</NextLink>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className={styles.authBtns}>
        <SignUpButton mode="modal">
          <Button size="small">
            Register Free
          </Button>
        </SignUpButton>
        <SignInButton mode="modal">
          <Button variant="secondary_mono_dark" size="small">
            Sign In
          </Button>
        </SignInButton>
      </div>
    </>
  );
};

export default Nav;
