import colors from './colors';
import fonts from './fonts';
import spacing from './spacing';
import borders from './borders';
import media from './mediaQueries';
import zIndex from './zIndex';

export default {
  colors,
  fonts,
  spacing,
  borders,
  media,
  zIndex,
};
