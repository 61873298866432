import {ZIndexValues} from './types';

const zIndex: ZIndexValues = {
  modal: 7000,
  top: 6000,
  dropdown: 5000,
  navigation: 4000,
  inlineLevel: 3000,
  blockLevel: 2000,
  background: 1000,
  base: 0,
  hidden: -1,
};

export default zIndex;
