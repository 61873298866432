import {Pallete} from './types';

const pallete: Pallete = {
  new_brand: {
    primary_100: '#F7F6F1',
    primary_90: '#F7F6F1',
    primary_70: '#F0EED2',
    primary_50: '#E2DFBF',
    primary_40: '#D0CCA5',
    primary_30: '#B2AC7B',
    primary_20: '#948D58',
    primary_10: '#776F3C',
    primary_0: '#625A28',
    primary__10: '#4D4217',

    danger_80: '#FFE5D8',
    danger_70: '#FFC5B1',
    danger_60: '#FE9E88',
    danger_50: '#FF7A6B',
    danger_40: '#FF3D3B',
    danger_30: '#DB2935',
    danger_20: '#B71D35',
    danger_10: '#931231',
    danger_0: '#7A0A2E',

    success_80: '#F2FBCE',
    success_70: '#E1F89E',
    success_60: '#C8EB6B',
    success_50: '#ABD847',
    success_40: '#83BF12',
    success_30: '#69A40C',
    success_20: '#528909',
    success_10: '#3F6E06',
    success_0: '#305B02',

    neutral_80: '#CDFAFF',
    neutral_70: '#99F1FF',
    neutral_60: '#66DFFE',
    neutral_50: '#40CAFB',
    neutral_40: '#02A7F9',
    neutral_30: '#0081D5',
    neutral_20: '#0162B3',
    neutral_10: '#00448F',
    neutral_0: '#003176',

    warning_80: '#FDF6CC',
    warning_70: '#FBE899',
    warning_60: '#F5D463',
    warning_50: '#ECC03D',
    warning_40: '#E0A203',
    warning_30: '#C08400',
    warning_20: '#A06A00',
    warning_10: '#815100',
    warning_0: '#6C4001',

    monochrome_100: '#FFFFFF',
    monochrome_98: '#FAFAFA',
    monochrome_95: '#F2F2F2',
    monochrome_90: '#E6E6E6',
    monochrome_80: '#CCCCCC',
    monochrome_75: '#BFBFBF',
    monochrome_70: '#B3B3B3',
    monochrome_60: '#999999',
    monochrome_50: '#808080',
    monochrome_40: '#666666',
    monochrome_30: '#4D4D4D',
    monochrome_20: '#333333',
    monochrome_15: '#262626',
    monochrome_10: '#1A1A1A',
    monochrome_5: '#0D0C07',
    monochrome_0: '#000000',

    brand_green: '#052923',
    brand_yellow: '#F2FF43',
  },

  background: {
    default: '#ffffff',

    green_gradient:
      'linear-gradient(45deg, #125C4F 24.48%, #197E6C 65.62%, #24B298 100%)',
    black: '#000000',

    gray: '#999999',
    light_gray: '#E6E6E6',
    lighter_gray: '#F2F2F2',

    white_alt: '#f2fffd',

    secondary_darker: '#EBBB14',
    secondary_white_alt: '#FFFCF2',

    accent_darker: '#FB6F3F',
    accent_white_alt: '#FFF5F2',
  },
  text: {
    default: '#000000',
    gray: '#999999',
    midle_light_gray: '#808080',

    white: '#ffffff',

    link_pending: '#1565C0',

    accent_darker: '#FB6F3F',

    secondary_darker: '#EBBB14',
  },
  icon: {
    default: '#ffffff',
  },

  button: {
    background: {
      dark: '#0D0C07',
      light: '#FFFFFF',
      gold: '#625A28',
      light_gold: '#FFFFFF',
      light_gray: '#E6E6E6',

      transparent: 'transparent',
    },
    text: {
      dark: '#ffffff',
      light: '#0D0C07',
      gold: '#ffffff',
      light_gold: '#F7F6F1',
      light_gray: '#0D0C07',

      transparent: '#0D0C07',
    },
    border: {
      dark: '#0D0C07',
      light: '#0D0C07',
      gold: '#625A28',
      light_gold: '#625A28',
      light_gray: '#E6E6E6',

      transparent: '#0D0C07',
    },
    active: {
      dark: '#0D0C07',
      light: '#FFFFFF',
      gold: '#625A28',
      light_gold: '#FFFFFF',
      light_gray: '#E6E6E6',

      transparent: '#FFFFFF',
    },
    disabled: {
      dark: '#666666',
      light: '#4D4D4D20',
      gold: '#625A28',
      light_gold: '#B2AC7B',
      light_gray: '#FAFAFA',
      transparent: '#4D4D4D20',
    },
    hover: {
      dark: '#333230',
      light: '#4D4D4D10',
      gold: '#948D58',
      light_gold: '#F2FF431A',
      light_gray: '#F2F2F2',

      transparent: '#4D4D4D10',
    },
    hover_border: {
      dark: '#333230',
      light: '#0D0C07',
      gold: '#948D58',
      light_gold: '#625A28',
      light_gray: '#F2F2F2',

      transparent: '#0D0C07',
    },
  },
  input: {
    background: {
      default: '#ffffff',
      white: '#ffffff',

      gray: '#ffffff',
      dark_gray: '#ffffff',

      accent_darker: '#ffffff',
      counter_current: '#ffffff',
    },
    border: {
      default: '#CCCCCC',
      white: '#BFBFBF',

      gray: '#999999',
      dark_gray: '#999999',

      accent_darker: '#FB6F3F',

      counter_current: '#999999',
    },
    text: {
      default: '#000000',
      white: '#000000',

      gray: '#000000',
      dark_gray: '#000000',

      accent_darker: '#000000',

      counter_current: '#FB6F3F',
    },
    placeholder: {
      default: '#808080',
      white: '#808080',

      gray: '#808080',
      dark_gray: '#000000',

      accent_darker: '#808080',
      counter_current: '#808080',
    },
  },
  border: {
    default: '#000000',

    accent_darker: '#FB6F3F',

    white: '#ffffff',
  },
};

export default pallete;
