'use client';
import {ThemeProvider, createGlobalStyle} from 'styled-components';
import {AxiosError} from 'axios';
import {light, media} from '@/themes';
import theme from '@/theme';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {ReactNode} from 'react';

const createQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {/*
        retry: (failureCount, error) => {
          if (error instanceof AxiosError && error?.response?.status === 401) {
            return false;
          }

          return failureCount <= 3;
        },*/
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });
};

const GlobalStyle = createGlobalStyle`
  /* stylelint-disable-next-line */
  .ReactQueryDevtoolsPanel * {
    color: white;
  }

  /**
  * We have to override Radix normal block scroll behavior when dialogs are
  * open. Since the block scroll behavior adds overflow: hidden to the body,
  * this is removing the scroll context on the sticky profile pic feature
  * breaking the sticky behavior.
  * reference: https://medium.com/@ehsaneona/overcoming-position-sticky-issues-solving-overflow-hidden-problems-with-overflow-clip-e8e79a7b0c34
  */
  html body[data-scroll-locked] {
    overflow: clip !important;
  }


  #root {
    position: relative;
    z-index: ${({theme}) => theme.zIndex.base};
  }
`;

const IndependentProviders = ({children}: {children: ReactNode}) => {
  return (
    <QueryClientProvider client={createQueryClient()}>
      <ThemeProvider
        theme={{
          ...light,
          ...media,
          ...theme.colors,
          ...theme.fonts,
          ...theme.spacing,
          ...theme.borders,
          ...theme.media,
          ...theme.zIndex,
        }}>
        <GlobalStyle></GlobalStyle>
        {children}
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default IndependentProviders;
