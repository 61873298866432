'use client';

import Image from 'next/image';
import Link from '../Link';
import Nav from './components/Nav';
import UserNav from './components/UserNav';
import Container from '../Container';
import styles from './styles.module.css';
import { SignedIn, SignedOut } from '@clerk/nextjs'

export default function Header() {

  return (
    <header className={styles.header}>
      <Container className={styles.contentWrapper}>
        <div>
          <Link
            href="/"
            aria-label="Offerwell Homepage">
            <Image
              src="/offerwell-logomark.svg"
              className={styles.logomark}
              width={56}
              height={24}
              alt="Offerwell Logomark"
              priority
              unoptimized
            />
            <Image
              src="/offerwell-logotype.svg"
              className={styles.logotype}
              width={126}
              height={24}
              alt="Offerwell Logotype"
              priority
              unoptimized
            />
          </Link>
        </div>
        <SignedOut>
          <Nav />
        </SignedOut>
        <SignedIn>
          <UserNav />
        </SignedIn>
      </Container>
    </header>
  )
}
