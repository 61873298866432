'use client'

import {PropsWithChildren} from 'react';
import useProfile from '../hooks/useProfile';
import {AuthContext} from '../context/AuthContext';
import { useAuth } from '@clerk/nextjs'

export default function AuthProvider({children}: PropsWithChildren) {
  const { isSignedIn } = useAuth()

  const {data: userProfile, status: userStatus} = useProfile(isSignedIn);

  const context = {
    user: userProfile?.data,
    userStatus: userStatus,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
