import light from './light.pallete';
import {
  Pallete,
  Text as TextColors,
  Background as TColorsBackground,
  Border as TColorsBorder,
} from './types';

export {light};

export type {Pallete, TextColors, TColorsBackground, TColorsBorder};
