import {Spacing} from './types';

export const baseSpacing = {
  none: 0,
  xs3: 4,
  xs2: 6,
  xs: 8,
  s: 12,
  m: 16,
  l: 20,
  xl: 24,
  xl2: 32,
  xl3: 40,
  xl4: 48,
  xl5: 56,
  xl6: 60,
  xl7: 64,
  xl8: 80,
  xl9: 128,
} as const;

const spacing: Spacing = Object.assign(baseSpacing, {
  desktop: {
    grid_gap: {
      small: baseSpacing.xs,
      medium: baseSpacing.m,
      large: baseSpacing.xl,
    },
    section: {
      gap: {
        xsmall: baseSpacing.m,
        small: baseSpacing.xl,
        medium: baseSpacing.xl2,
        large: baseSpacing.xl3,
        xlarge: baseSpacing.xl5,
      },
      mar_tb: {
        small: baseSpacing.xl2,
        medium: baseSpacing.xl4,
        large: baseSpacing.xl7,
      },
    },
    page: {
      gap: {
        small: baseSpacing.xl2,
        main: baseSpacing.xl4,
        large: baseSpacing.xl8,
      },
      mar_tb: {
        small: baseSpacing.xl4,
        main: baseSpacing.xl8,
        large: baseSpacing.xl9,
      },
      mar_hor: {main: baseSpacing.xl8},
    },
    element: {
      gap: {
        xxsmall: baseSpacing.xs3,
        xsmall: baseSpacing.xs,
        small: baseSpacing.s,
        medium: baseSpacing.m,
        large: baseSpacing.l,
        xlarge: baseSpacing.xl,
        xxlarge: baseSpacing.xl2,
      },
      pad: {
        xxsmall: baseSpacing.xs3,
        xsmall: baseSpacing.xs,
        small: baseSpacing.s,
        medium: baseSpacing.m,
        large: baseSpacing.l,
        xlarge: baseSpacing.xl,
        xxlarge: baseSpacing.xl2,
      },
    },
  },
  tablet: {
    grid_gap: {
      small: baseSpacing.xs,
      medium: baseSpacing.m,
      large: baseSpacing.xl,
    },
    section: {
      gap: {
        xsmall: baseSpacing.m,
        small: baseSpacing.xl,
        medium: baseSpacing.xl2,
        large: baseSpacing.xl3,
        xlarge: baseSpacing.xl5,
      },
      mar_tb: {
        small: baseSpacing.xl2,
        medium: baseSpacing.xl4,
        large: baseSpacing.xl7,
      },
    },
    page: {
      gap: {
        small: baseSpacing.xl2,
        main: baseSpacing.xl6,
        large: baseSpacing.xl8,
      },
      mar_tb: {
        small: baseSpacing.xl2,
        main: baseSpacing.xl6,
        large: baseSpacing.xl8,
      },
      mar_hor: {main: baseSpacing.xl7},
    },
    element: {
      gap: {
        xxsmall: baseSpacing.xs3,
        xsmall: baseSpacing.xs,
        small: baseSpacing.s,
        medium: baseSpacing.m,
        large: baseSpacing.l,
        xlarge: baseSpacing.xl,
        xxlarge: baseSpacing.xl2,
      },
      pad: {
        xxsmall: baseSpacing.xs3,
        xsmall: baseSpacing.xs,
        small: baseSpacing.s,
        medium: baseSpacing.m,
        large: baseSpacing.l,
        xlarge: baseSpacing.xl,
        xxlarge: baseSpacing.xl2,
      },
    },
  },
  mobile: {
    grid_gap: {
      small: baseSpacing.xs,
      medium: baseSpacing.m,
      large: baseSpacing.xl,
    },
    section: {
      gap: {
        xsmall: baseSpacing.m,
        small: baseSpacing.xl,
        medium: baseSpacing.xl2,
        large: baseSpacing.xl3,
        xlarge: baseSpacing.xl5,
      },
      mar_tb: {
        small: baseSpacing.xl2,
        medium: baseSpacing.xl4,
        large: baseSpacing.xl7,
      },
    },
    page: {
      gap: {
        small: baseSpacing.xl2,
        main: baseSpacing.xl6,
        large: baseSpacing.xl8,
      },
      mar_tb: {
        small: baseSpacing.xs,
        main: baseSpacing.m,
        large: baseSpacing.xl2,
      },
      mar_hor: {main: baseSpacing.m},
    },
    element: {
      gap: {
        xxsmall: baseSpacing.xs3,
        xsmall: baseSpacing.xs2,
        small: baseSpacing.xs,
        medium: baseSpacing.s,
        large: baseSpacing.m,
        xlarge: baseSpacing.l,
        xxlarge: baseSpacing.xl,
      },
      pad: {
        xxsmall: baseSpacing.xs3,
        xsmall: baseSpacing.xs,
        small: baseSpacing.s,
        medium: baseSpacing.m,
        large: baseSpacing.l,
        xlarge: baseSpacing.xl,
        xxlarge: baseSpacing.xl2,
      },
    },
  },
});

export default spacing;
