import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/Tooltip';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/Dropdown';
import Button from '@/components/ui/Button';
import Dashboard from '@/components/ui/icons/Dashboard';
import Avatar from '@/components/ui/Avatar';
import {
  Archive,
  Buildings,
  Calendar,
  Files,
  Gear,
  MagnifyingGlass,
  SignOut,
  User,
} from '@phosphor-icons/react/dist/ssr';
import NextLink from 'next/link';
import styles from './styles.module.css';

import { UserButton } from '@clerk/nextjs';

const DotIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
    </svg>
  )
}

const UserNav = () => {
  return (
    <div className={styles.userNav}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="primary_mono_light"
              aria-label="Find a listing"
              isIconOnly
              asChild>
              <NextLink href="/">
                <MagnifyingGlass />
              </NextLink>
            </Button>
          </TooltipTrigger>
          <TooltipContent>Find a listing</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DropdownMenu modal={false}>
        <TooltipProvider>
          <Tooltip>
            <DropdownMenuTrigger asChild>
              <TooltipTrigger asChild>
                <Button
                  variant="primary_mono_light"
                  aria-label="Dashboard"
                  isIconOnly>
                  <Dashboard />
                </Button>
              </TooltipTrigger>
            </DropdownMenuTrigger>
            <TooltipContent>Dashboard</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <DropdownMenuContent onCloseAutoFocus={(e) => e.preventDefault()}>
          <DropdownMenuGroup>
            <DropdownMenuItem asChild>
              <NextLink href="/agent/dashboard?tab=listings">
                <Buildings size={20} /> My Listings
              </NextLink>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <NextLink href="/agent/dashboard?tab=offers">
                <Files size={20} />
                My Offers
              </NextLink>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <NextLink href="/agent/dashboard?tab=showings">
                <Calendar size={20} /> My calendar
              </NextLink>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <NextLink href="/agent/archive">
                <Archive size={20} /> Archive
              </NextLink>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <UserButton appearance={{
        elements: { avatarBox: styles.customClerkAvatar },
      }}>
        <UserButton.MenuItems>
          <UserButton.Link
            label="Settings"
            labelIcon={<DotIcon />}
            href={`/agent/settings?active=general`}
          />
        </UserButton.MenuItems>
      </UserButton>

    </div>
  );
};

export default UserNav;

/*
Work in progress.

import NotificationsBtn from '../NotificationsBtn';
import NotificationTab from '@/features/notifications/components/NottificationTab/NotificationTab'
import { LicensePage } from '@/components/v0/LicensePage'


<UserButton.UserProfilePage label="Notifications" url="custom2" labelIcon={<DotIcon />}>
  <NotificationTab />
</UserButton.UserProfilePage>
<UserButton.UserProfilePage label="Licenses" url="custom3" labelIcon={<DotIcon />}>
  <LicensePage />
</UserButton.UserProfilePage>
*/
