import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import {ComponentPropsWithoutRef, ElementRef, forwardRef} from 'react';
import {cx} from 'class-variance-authority';
import styles from './styles.module.css';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({className, sideOffset = 8, ...props}, forwardedRef) => (
  <TooltipPrimitive.Content
    ref={forwardedRef}
    className={cx(styles.tooltip, className)}
    sideOffset={sideOffset}
    {...props}
  />
));

TooltipContent.displayName = 'TooltipContent';

export {TooltipProvider, Tooltip, TooltipTrigger, TooltipContent};
