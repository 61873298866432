import {createContext} from 'react';
import type {UserProfile} from '@/features/types';

type AuthContext = {
  user: UserProfile | undefined;
  userStatus: 'error' | 'success' | 'loading';
}

export const AuthContext = createContext<AuthContext | null>(null);

if (process.env.NODE_ENV !== 'production') {
  AuthContext.displayName = 'AuthContext';
}
