import {FontSizes, Fonts} from './types';

const deskScale = 1.25;
const tabletMobileScale = 1.2;
const deskBase = 16;
const mobileBase = 14;

const fontSizes = (scale: number, base: number): FontSizes => {
  return {
    base: base,
    h6: scale * base,
    h5: Math.pow(scale, 2) * base,
    h4: Math.pow(scale, 3) * base,
    h3: Math.pow(scale, 4) * base,
    h2: Math.pow(scale, 5) * base,
    h1: Math.pow(scale, 6) * deskBase,
    h_display: Math.pow(scale, 5) * base * scale * 2,
    p_xsmall: base / Math.pow(scale, 2),
    p_small: base / scale,
    p_main: base,
    p_medium: base * scale,
    p_large: Math.pow(scale, 2) * base,
    p_xlarge: Math.pow(scale, 3) * base,
  };
};
const fonts: Fonts = {
  fontFamily: {
    ui: 'var(--font-inter)',
    brand: 'var(--font-pp-fragment)',
  },
  fontWeights: {light: 300, regular: 400, medium: 500, bold: 700},
  lineHeights: {1: '150%', 2: '130%', 3: '110%', 4: '100%'},
  letterSpacing: {1: '0', 2: '-0.48px'},
  textDecoration: {
    none: 'none',
    underline: 'underline',
    line_through: 'line-through',
  },
  textTransform: {
    none: 'none',
    uppercase: 'uppercase',
    capitalize: 'capitalize',
    lowercase: 'lowercase',
  },

  fontSizes: {
    desktop: fontSizes(deskScale, deskBase),
    tablet: fontSizes(tabletMobileScale, deskBase),
    mobile: fontSizes(tabletMobileScale, mobileBase),
  },
};

export default fonts;
