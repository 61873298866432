import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import {ComponentPropsWithoutRef, ElementRef, forwardRef} from 'react';
import {cx} from 'class-variance-authority';
import {Check} from '@phosphor-icons/react/dist/ssr';
import styles from './styles.module.css';

const DropdownMenu = DropdownMenuPrimitive.Root;

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const DropdownMenuGroup = DropdownMenuPrimitive.Group;

const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

const DropdownMenuSub = DropdownMenuPrimitive.Sub;

const DropdownMenuArrow = DropdownMenuPrimitive.Arrow;

const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

const DropdownMenuContent = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({children, sideOffset = 8, className, ...props}, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        ref={forwardedRef}
        sideOffset={sideOffset}
        className={cx(styles.content, className)}
        {...props}>
        {children}
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  );
});

DropdownMenuContent.displayName = 'DropdownMenuContent';

const DropdownMenuItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Item>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
>(({className, ...props}, forwardedRef) => (
  <DropdownMenuPrimitive.Item
    ref={forwardedRef}
    className={cx(styles.item, className)}
    {...props}
  />
));

DropdownMenuItem.displayName = 'DropdownMenuItem';

const DropdownMenuLabel = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Label>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label>
>(({className, ...props}, forwardedRef) => (
  <DropdownMenuPrimitive.Label
    ref={forwardedRef}
    className={cx(styles.label, className)}
    {...props}
  />
));

DropdownMenuLabel.displayName = 'DropdownMenuLabel';

const DropdownMenuItemIndicator = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.ItemIndicator>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.ItemIndicator>
>(({className, ...props}, forwardedRef) => {
  return (
    <div className={styles.itemIndicatorWrapper}>
      <DropdownMenuPrimitive.ItemIndicator
        ref={forwardedRef}
        className={cx(styles.itemIndicator, className)}
        {...props}
      />
    </div>
  );
});

DropdownMenuItemIndicator.displayName = 'DropdownMenuItemIndicator';

const DropdownMenuCheckboxItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({children, className, ...props}, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.CheckboxItem
      ref={forwardedRef}
      className={cx(styles.item, className)}
      {...props}>
      <DropdownMenuItemIndicator>
        <Check size={14} weight="bold"></Check>
      </DropdownMenuItemIndicator>
      {children}
    </DropdownMenuPrimitive.CheckboxItem>
  );
});

DropdownMenuCheckboxItem.displayName = 'DropdownMenuCheckboxItem';

const DropdownMenuRadioItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.DropdownMenuRadioItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.DropdownMenuRadioItem>
>(({children, className, ...props}, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.RadioItem
      ref={forwardedRef}
      className={cx(styles.item, className)}
      {...props}>
      <DropdownMenuItemIndicator />
      {children}
    </DropdownMenuPrimitive.RadioItem>
  );
});

DropdownMenuRadioItem.displayName = 'DropdownMenuRadioItem';

const DropdownMenuSubTrigger = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger>
>(({children, className, ...props}, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.SubTrigger
      ref={forwardedRef}
      className={cx(styles.item, className)}
      {...props}>
      {children}
    </DropdownMenuPrimitive.SubTrigger>
  );
});

DropdownMenuSubTrigger.displayName = 'DropdownMenuSubTrigger';

const DropdownMenuSubContent = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({children, className, ...props}, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.SubContent
        ref={forwardedRef}
        className={cx(styles.content, className)}
        {...props}>
        {children}
      </DropdownMenuPrimitive.SubContent>
    </DropdownMenuPrimitive.Portal>
  );
});

DropdownMenuSubContent.displayName = 'DropdownMenuSubContent';

const DropdownMenuSeparator = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Separator>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({className, ...props}, forwardedRef) => (
  <DropdownMenuPrimitive.Separator
    ref={forwardedRef}
    className={cx(styles.separator, className)}
    {...props}
  />
));
DropdownMenuSeparator.displayName = 'DropdownMenuSeparator';

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
  DropdownMenuGroup,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
  DropdownMenuSeparator,
  DropdownMenuArrow,
  DropdownMenuPortal,
};
